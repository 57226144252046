import PaginationRequest from "@/models/general/PaginationRequest";
import PaginationResponse from "@/models/general/PaginationResponse";
import TransferRow from "@/models/weewhouse/TransferRow";
import SaveTransfer from "@/models/weewhouse/SaveTransfer";
import FiltersTransfers from "@/models/weewhouse/filters/FiltersTransfers";
import { AxiosResponse } from "axios";
import GeneralService from "../interfaces/GeneralService";
import MessageService from "../interfaces/MessageService";
import TableTranfersService from "./interfaces/TableTranfersService";
import SwalMessageService from "../SwalMessageService";
import ApiService from "../ApiService";
import { getMessageError, getMessageHeader } from "@/core/helpers/messageFromPulmeros";
import CrudService from "../general/interfaces/CrudService";
import NewTransfer from "@/models/weewhouse/NewTransfer";
import { translate } from "@/core/plugins/i18n";
import TableTranferDetailsService from "./interfaces/TableTransferDetailService";
import FiltersTransferDetails from "@/models/weewhouse/filters/FiltersTransferDetails";
import TransferDetailRow from "@/models/weewhouse/TransferDetailRow";
import NewTransferDetailService from "./interfaces/NewTransferDetailService";
import NewTransferDetails from "@/models/weewhouse/NewTransferDetails";

export default class WarehouseTransfersService implements GeneralService, TableTranfersService, TableTranferDetailsService, NewTransferDetailService
    , CrudService<NewTransfer,NewTransfer> {
   
    
    apiController = 'transfers';
    messageService: MessageService = new SwalMessageService();
    
    async searchTableTransfers(pagination: PaginationRequest, filters: FiltersTransfers)
        : Promise<AxiosResponse<PaginationResponse<TransferRow>>> {
        
        let customerIdsFilters = '';
        let productIdsFilters = '';
        if (filters.customerIds) { 
            filters.customerIds.every(customerId => {
                customerIdsFilters += (customerId && customerId != '' ? `&Criteria.CustomerIds=${customerId}` : '')
                return true;
            })
        }
         if (filters.productIds) {
            filters.productIds.every(productId => {
                productIdsFilters += (productId && productId != '' ? `&Criteria.ProductIds=${productId}` : '')
                return true;
            })
        }
        return ApiService.get<PaginationResponse<TransferRow>>(this.apiController,
            `GetPagedList?`//puede cambiar esta ruta
            + `CurrentPage=${pagination.currentPage}`
            + `&PageSize=${pagination.pageSize}`
            + (filters.folio && filters.folio != '' ? `&Criteria.Folio=${filters.folio}` : '')
            + (filters.ocFolio && filters.ocFolio != '' ? `&Criteria.RelatedFolio=${filters.ocFolio}` : '')
            + (filters.filterType && filters.filterType != '' ? `&Criteria.MovementTypeId=${filters.filterType}` : '')
            + (customerIdsFilters && customerIdsFilters != '' ? customerIdsFilters : '')
            + (productIdsFilters && productIdsFilters != '' ? productIdsFilters : '')
            + (filters.startDate && filters.startDate != '' ? `&Criteria.InitialDate=${filters.startDate}` : '')
            + (filters.endDate && filters.endDate != '' ? `&Criteria.FinalDate=${filters.endDate}` : '')
            + (filters.status  != undefined && !isNaN(parseFloat(filters.status)) ? `&Criteria.status=${filters.status}` : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async getInfo(id: string): Promise<AxiosResponse<NewTransfer>> {
        return  ApiService.get<NewTransfer>(this.apiController, 
            `${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    async save(form: NewTransfer): Promise<AxiosResponse<boolean>> {
        return ApiService.post<boolean>(this.apiController
            , form
        ).then( (resp) => {
            if(resp.status > 200 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            return {...resp, data: (resp.status >= 200 && resp.status < 300)};
        }).catch((error) => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }
    // ----------------------------------------------------------
    async guardar(form: NewTransfer): Promise<AxiosResponse<SaveTransfer>> {
        return ApiService.post<SaveTransfer>(this.apiController, form)
            .then((resp) => {
                if(resp.status > 200 && resp.status < 500){
                    getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
                }
                return { ...resp, id: resp.data.id };
        }).catch((error) => {
                getMessageError(error, this.messageService, "MessageError");
                const defaultSaveTransfer: SaveTransfer = {
                    isCorrect: false,
                    TransferId: '',
                    id: ''
                };
                return {
                    data: defaultSaveTransfer,
                    status: 500,
                    statusText: "Error",
                    headers: {},
                    config: {}
                } as AxiosResponse<SaveTransfer>;
            });
    }
    async update(id: string, newInfo: NewTransfer): Promise<AxiosResponse<boolean>> {
        return  ApiService.update<boolean>(this.apiController 
            , id
            , newInfo
        ).then( resp => {
            return {...resp, data: (resp.status >= 200 && resp.status < 300)};
        }).catch(error => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }
    async committed(id: string): Promise<AxiosResponse<boolean>> {
       return  ApiService.update<boolean>(this.apiController 
           , `${id}/Committed`
           , {}
        ).then( resp => {
            return {...resp, data: (resp.status >= 200 && resp.status < 300)};
        }).catch(error => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }
    async sendEndCapture(id: string): Promise<AxiosResponse<boolean>> {
        return  ApiService.update<boolean>(this.apiController 
           , `${id}/EndCapture`
           , {}
        ).then( resp => {
            return {...resp, data: (resp.status >= 200 && resp.status < 300)};
        }).catch(error => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }

    async searchTableTransferDetails(transferId: string, pagination: PaginationRequest, filters: FiltersTransferDetails): Promise<AxiosResponse<PaginationResponse<TransferDetailRow>>> {
        let productIdsFilters = '';
        if (filters.productIds) {
            filters.productIds.every(productId => {
                productIdsFilters += (productId && productId != '' ? `&Criteria.ProductIds=${productId}` : '')
                return true;
            })
        }

         return ApiService.get<PaginationResponse<TransferRow>>(this.apiController, 
            `Detail/GetPagedList/${transferId}?`//puede cambiar esta ruta
            + `CurrentPage=${pagination.currentPage}`
            + `&PageSize=${pagination.pageSize}`
            + (productIdsFilters && productIdsFilters != '' ? productIdsFilters : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async getInfoDetail(id: string): Promise<AxiosResponse<NewTransferDetails>> {
         return  ApiService.get<NewTransferDetails>(this.apiController, 
            `Detail/${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    async saveDetail(idTransfer:string, form: NewTransferDetails): Promise<AxiosResponse<boolean>> {
         return ApiService.post<boolean>(this.apiController + `/Detail/${idTransfer}`
            , form
        ).then( (resp) => {
            if(resp.status > 200 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            return {...resp, data: (resp.status >= 200 && resp.status < 300)};
        }).catch((error) => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }
    updateDetail(id: string, newInfo: NewTransferDetails): Promise<AxiosResponse<boolean>> {
        throw new Error("Method not implemented.");//aun no se implementa
    }
}